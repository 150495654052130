/* src/styles/App.css */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  
  .site-layout-content {
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .layout {
    background-color: white; /* Set background color to white */
}
.content {
    background-color: white; /* Ensure content background matches */
}

  